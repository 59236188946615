/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'emoji-sunglasses-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M8 16A8 8 0 108 0a8 8 0 000 16M2.31 5.243A1 1 0 013.28 4H6a1 1 0 011 1v.116A4.2 4.2 0 018 5c.35 0 .69.04 1 .116V5a1 1 0 011-1h2.72a1 1 0 01.97 1.243l-.311 1.242A2 2 0 0111.439 8H11a2 2 0 01-1.994-1.839A3 3 0 008 6c-.393 0-.74.064-1.006.161A2 2 0 015 8h-.438a2 2 0 01-1.94-1.515zM4.969 9.75A3.5 3.5 0 008 11.5a3.5 3.5 0 003.032-1.75.5.5 0 11.866.5A4.5 4.5 0 018 12.5a4.5 4.5 0 01-3.898-2.25.5.5 0 01.866-.5z"/>',
    },
});
